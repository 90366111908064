<template>
  <v-sheet color="transparent" class="images-carousel">
    <v-slide-group show-arrows>
      <v-slide-item v-for="item in items" :key="item.id">
        <v-card color="transparent" class="item" @click.native="goToContent(item)">
          <div
            class="container-img"
            :class="{ 'container-img--video': isContentType(item, 'video') }"
          >
            <img v-if="hasImage(item)" :src="item.coverImage" class="container-img__img" />

            <template v-else>
              <v-icon v-if="isContentType(item, 'video')" class="container-img__placeholder">
                mdi-play-circle
              </v-icon>

              <v-icon v-if="isContentType(item, 'link')" class="container-img__icon">
                mdi-web
              </v-icon>

              <v-icon v-if="isContentType(item, 'file')" class="container-img__icon">
                mdi-file
              </v-icon>
            </template>
          </div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="item__text" v-bind="attrs" v-on="on">
                {{ item.name }}
              </div>
            </template>

            <span>{{ item.name }}</span>
          </v-tooltip>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
export default {
  name: 'ImagesCarousel',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedItem: 0,
  }),
  methods: {
    goToContent(item) {
      if (item.id === this.$route?.params?.contentItemId) {
        return;
      }

      if (this.isContentType(item, 'link') || this.isContentType(item, 'file')) {
        window.open(item.content[0].url[0], '_blank');
      } else {
        this.$router.push({
          name: 'Content',
          params: {
            eventId: item.eventId,
            categoryId: item.categoryId,
            contentItemId: item.id,
          },
        });
      }
    },
    hasImage(item) {
      return !!item.coverImage;
    },
    isContentType(item, type) {
      return item.content[0].type === type;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.images-carousel {
  display: flex;
  flex-direction: column;

  width: 80%;
  margin: auto;
}

.item {
  margin: 0.5rem;
  padding: 0.2rem;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &__text {
    width: 10rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.container-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 0 !important;
  width: 10rem;
  aspect-ratio: 16/9;

  &--video {
    background-color: #ffffff;
  }

  &__img {
    width: 100%;
    aspect-ratio: 16/9;
  }

  &__placeholder {
    color: var(--v-gray-base);
    max-width: 100px;
    text-align: center;
    font-size: 50px;
  }

  &__icon {
    max-width: 100px;
    text-align: center;
    font-size: 60px;
  }
}
</style>
